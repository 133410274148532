import user from './user'

let basepros = process.env.NODE_ENV;
let apiBaseUrl = process.env.VUE_APP_BASE_API
// console.log(process.env)
// console.log(basepros)

// if (basepros == "production") console.log('这是正式环境')
// if (basepros == "development") console.log('这是开发环境')
// if (basepros == "test") console.log('这是测试环境')

const manageUrl = {
  baseUrl: apiBaseUrl,
  // 权限菜单接口
  ...user,
}
export default manageUrl
