import { RouteRecordRaw, createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/console/gho',
  },
  {
    path: '/console/login',
    name: 'login',
    component: () => import('../components/login.vue')
  },
  {
    path: '/console/paygress',
    name: 'paygress',
    component: () => import('../components/paygress.vue')
  },
  {
    path: '/console/buycardgress',
    name: 'buycardgress',
    component: () => import('../components/buycardgress.vue')
  },
  {
    path: '/console/share',
    name: 'share',
    component: () => import('../components/share.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../components/404.vue')
  },
]

// -----------------------
// 动态新增路由
import server from './server'
import school from './school'
import college from './college'
import model from './model'
import manage from './manage'
import help from './help'
import outsource from './outsource'
const asyncRoutes = [
  server,
  school,
  college,
  model,
  manage,
  help,
  outsource,
  // 404 page must be placed at the end !!!
  // { path: '/:catchAll(.*)', redirect: '/404', hidden: true }
]

const router = {
  router: createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  }),
  asyncRoutes: asyncRoutes
}

export default router
