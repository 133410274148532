const server = {
    path: "/model",
    name: "model",
    component: () => import("../views/home.vue"),
    redirect: "/console/apiKeys",
    meta: { title: "menuModelHead", },
    children: [
        {
            path: '/console/apiKeys',
            name: 'apiKeys',
            component: () => import('../views/model/apiKeys.vue'),
            meta: {
                title: "menuModelAPIKey",
                icon: "menuModelAPIKey",
            },
        },
        {
            path: '/console/chat',
            name: 'chat',
            component: () => import('../views/model/chat.vue'),
            meta: {
                title: "menuModelChat",
                icon: "menuModelChat",
            },
        },
    ]
};

export default server;
