const server = {
    path: "/help",
    name: "help",
    component: () => import("../views/home.vue"),
    redirect: "/console/contactUs",
    meta: { title: "menuHelpHead", },
    children: [
        {
            path: '/console/contactUs',
            name: 'contactUs',
            component: () => import('../views/help/contactUs.vue'),
            meta: {
                title: "menuHelpContact",
                icon: "menuHelpContact",
            },
        },
        {
            path: '/console/docs',
            name: 'docs',
            component: () => import('../views/help/docs.vue'),
            meta: {
                title: "menuHelpDocs",
                icon: "menuHelpDocs",
            },
        }
    ]
};

export default server;
