const server = {
    path: "/school",
    name: "school",
    component: () => import("../views/home.vue"),
    redirect: "/console/mySchool",
    meta: { title: "menuSchoolHead", },
    children: [
        {
            path: '/console/teacherHome',
            name: 'teacherHome',
            component: () => import('../views/school/teacherHome.vue'),
            meta: {
                title: "menuSchoolTeacherHome",
                icon: "menuSchoolTeacherHome",
            },
        },
        {
            path: '/console/teacherDetails',
            name: 'teacherDetails',
            component: () => import('../views/school/teacherDetails.vue'),
            meta: {
                title: "menuSchoolTeacherHome",
                icon: "menuSchoolTeacherHome",
            },
        },
        {
            path: '/console/teacherClass',
            name: 'teacherClass',
            component: () => import('../views/school/teacherClass.vue'),
            meta: {
                title: "menuSchoolTeacherHome",
                icon: "menuSchoolTeacherHome",
            },
        },
        {
            path: '/console/mySchool',
            name: 'mySchool',
            component: () => import('../views/school/mySchool.vue'),
            meta: {
                title: "menuSchoolMySchool",
                icon: "menuSchoolMySchool",
            },
        },
        {
            path: '/console/schoolDetails',
            name: 'schoolDetails',
            component: () => import('../views/school/schoolDetails.vue'),
            meta: {
                title: "menuSchoolMySchool",
                icon: "menuSchoolMySchool",
            },
        },
        {
            path: '/console/schoolManage',
            name: 'schoolManage',
            component: () => import('../views/school/schoolManage.vue'),
            meta: {
                title: "menuSchoolSchoolManage",
                icon: "menuSchoolSchoolManage",
            },
        },
        {
            path: '/console/schoolNew',
            name: 'schoolNew',
            component: () => import('../views/school/schoolNew.vue'),
            meta: {
                title: "menuSchoolSchoolManage",
                icon: "menuSchoolSchoolManage",
            },
        },
    ]
};

export default server;
