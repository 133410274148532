const server = {
    path: "/manage",
    name: "manage",
    component: () => import("../views/home.vue"),
    redirect: "/console/billing",
    meta: { title: "menuManageHead", },
    children: [
        {
            path: '/console/hashrate',
            name: 'hashrate',
            component: () => import('../views/manage/hashrate.vue'),
            meta: {
                title: "menuManageHashrate",
                icon: "menuManageHashrate",
            },
        },
        {
            path: '/console/billing',
            name: 'billing',
            component: () => import('../views/manage/billing.vue'),
            meta: {
                title: "menuManageBilling",
                icon: "menuManageBilling",
            },
        },
        {
            path: '/console/account',
            name: 'account',
            component: () => import('../views/manage/account.vue'),
            meta: {
                title: "menuManageAccount",
                icon: "menuManageAccount",
            },
        },
        {
            path: '/console/file',
            name: 'file',
            component: () => import('../views/manage/file.vue'),
            meta: {
                title: "menuManageFile",
                icon: "menuManageFile",
            },
        },

    ]
};

export default server;
