import { createI18n } from 'vue-i18n';
import ZH from './1zh/index.js';
import TC from './2tc/index.js';
import EN from './3en/index.js';
const messages = {
    zh: { ...ZH },
    tc: { ...TC },
    en: { ...EN },
};

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: '',
    messages
});
export default i18n; 