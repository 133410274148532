const server = {
    path: "/server",
    name: "server",
    component: () => import("../views/home.vue"),
    redirect: "/console/gho",
    meta: { title: "menuServerHead", },
    children: [
        {
            path: '/console/homePage',
            name: 'homePage',
            component: () => import('../views/server/homePage.vue'),
            meta: {
                title: "menuServerHomePage",
                icon: "menuServerHomePage",
            },
        },
        {
            path: '/console/gho',
            name: 'gho',
            component: () => import('../views/server/gho.vue'),
            meta: {
                title: "menuServerGho",
                icon: "menuServerGho",
            },
        },
        {
            path: '/console/ghoDetails',
            name: 'ghoDetails',
            component: () => import('../views/server/ghoDetails.vue'),
            meta: {
                title: "menuServerGho",
                icon: "menuServerGho",
            },
        },
        {
            path: '/console/pod',
            name: 'pod',
            component: () => import('../views/server/pod.vue'),
            meta: {
                title: "menuServerPod",
                icon: "menuServerPod",
            },
        },
        {
            path: '/console/podImage',
            name: 'podImage',
            component: () => import('../views/server/podImage.vue'),
            meta: {
                title: "menuServerPodImage",
                icon: "menuServerPodImage",
            },
        },
        {
            path: '/console/framer',
            name: 'framer',
            component: () => import('../views/server/framer.vue'),
            meta: {
                title: "menuServerFramer",
                icon: "menuServerFramer",
            },
        },
        {
            path: '/console/framerDetails',
            name: 'framerDetails',
            component: () => import('../views/server/framerDetails.vue'),
            meta: {
                title: "menuServerFramer",
                icon: "menuServerFramer",
            },
        },
        {
            path: '/console/framerSave',
            name: 'framerSave',
            component: () => import('../views/server/framerSave.vue'),
            meta: {
                title: "menuServerFramer",
                icon: "menuServerFramer",
            },
        },


    ]
};

export default server;
