const server = {
    path: "/outsource",
    name: "outsource",
    component: () => import("../views/theme.vue"),
    redirect: "/console/need",
    meta: { title: "menuOutsourceHead", },
    children: [
        {
            path: '/console/need',
            name: 'need',
            component: () => import('../views/outsource/need.vue'),
            meta: {
                title: "menuOutsourceNeed",
                icon: "menuOutsourceNeed",
            },
        },

    ]
};

export default server;
